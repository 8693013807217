export enum SourceTypeEnum {
  HashTag,
  UserName,
  Product,
  InstagramHashTag,
  InstagramUserName,
  Undefined,
  FailtureLogin,
  Clear
}
export class GetVideoByJobRequest {
  public data: string;
  public type: number;
  constructor(data?: string, type?: number) {
    this.data = data ?? "";
    this.type = type ?? SourceTypeEnum.HashTag;
  }
}

export class GetVideoByJobInstagramRequest {
  public data: string;
  public type: number;
  public isBusinessUser: boolean;
  constructor(data?: string, type?: number, isBusinessUser?: boolean) {
    this.data = data ?? "";
    this.type = type ?? SourceTypeEnum.InstagramHashTag;
    this.isBusinessUser = isBusinessUser ?? false;
  }
}
