export class AppSettingWidget {
  static Tiktok = {
    LimitVideos: 50,
    DefaultItemGetmore: 6,
    NumberPerRow: 4,
    AccentColor: "#000000",
    ItemBackground: "#fafafa",
    ItemColor: "#000000",
    Title: "My TikTok Feed",
    Caption: "",
    LabelReadmore: "Read more",
    LabelView: "View more",
    ButtonColor: "#FF0B53",
    ItemGetMore: 6
  };
  static Instagram = {
    LimitVideos: 50,
    DefaultItemGetmore: 6,
    NumberPerRow: 4,
    LoadmoreBackground:
      "linear-gradient(90.43deg, #1877F2 -127.39%, #CE00F2 -41.29%, #FF5656 86.62%, #FF6C00 210.4%)",
    LabelLoadmore: "Load more",
    LabelReadmore: "readmore",
    Title: "Follow us on Instagram",
    ItemBackground: "#000000",
    ItemColor: "#fafafa",
    ButtonColor: "#FF0B53",
    ItemGetMore: 6,
    ItemGetMoreStories: 20
  };
}
