import { ICloneStore } from "common/interfaces/ICloneStore";
import { ProductResponse } from "repositories/dtos/responses/ProductResponse";
import {
  ContextConfiguration,
  TiktokWidgetResponse
} from "repositories/dtos/responses/TiktokWidgetResponse";
import { TemplateType } from "Dependencies/TikTokLayout/LayoutTemplateType";
import { AppSettingWidget } from "DefaultConfiguration/AppSettingWidget";
import { SourceTypeEnum } from "repositories/dtos/requests/GetVideoByJobRequest";

export interface ISettingProviderWidget {
  id?: string;
  title?: string;
  source?: number;
  valueSource?: string;
  layout?: TemplateType;
  header?: "enable" | "disable";
  titleHeader?: string;
  caption?: string;
  labelReadMore?: string;
  labelView?: string;
  showProfile?: "enable" | "disable";
  showNetworkIcon?: "enable" | "disable";
  accentColor?: string;
  itemBackground?: string;
  itemColor?: string;
  numberItemPerRow?: number;
  customCss?: string;
  buttonColor?: string;
  products?: ProductResponse[];
  disableShowItems?: string[];
  itemSorts?: string[];
  numberItems?: number;
  itemsGetmore?: number;
  formatCurrentcy?: string;
  contextConfiguration?: ContextConfiguration;
}

export class SettingProviderWidget implements ISettingProviderWidget {
  id?: string;
  title?: string;
  source?: number;
  valueSource?: string;
  layout?: TemplateType;
  header?: "enable" | "disable";
  titleHeader?: string;
  caption?: string;
  labelReadMore?: string;
  labelView?: string;
  showProfile?: "enable" | "disable";
  showNetworkIcon?: "enable" | "disable";
  accentColor?: string;
  itemBackground?: string;
  itemColor?: string;
  numberItemPerRow?: number;
  customCss?: string;
  buttonColor?: string;
  numberItems?: number;
  products?: ProductResponse[];
  disableShowItems?: string[];
  itemSorts?: string[];
  itemsGetmore?: number;
  formatCurrentcy?: string;
  contextConfiguration?: ContextConfiguration;
  constructor(dto?: TiktokWidgetResponse) {
    this.id = dto?.id;
    this.title = dto?.widgetTitle || "";
    this.source =
      dto?.sourceType.toLowerCase() === "hashtag"
        ? SourceTypeEnum.HashTag
        : SourceTypeEnum.UserName;
    this.valueSource = dto?.valueSource || "";
    this.layout = dto?.setting.layoutType || 0;
    this.header = dto?.header.enable ? "enable" : "disable";
    this.titleHeader = dto?.header.title || "";
    this.caption = dto?.header.caption || "";
    this.labelReadMore = dto?.setting.labelReadMore || "";
    this.labelView = dto?.setting.labelViewMore || "";
    this.showProfile = dto?.setting.showProfile ? "enable" : "disable";
    this.showNetworkIcon = dto?.setting.showNetworkIcon ? "enable" : "disable";
    this.accentColor = dto?.setting.accentColor || "";
    this.itemBackground = dto?.setting.backGround || "";
    this.itemColor = dto?.setting.color || "";
    this.numberItemPerRow =
      dto?.setting.numberPerRow || AppSettingWidget.Tiktok.NumberPerRow;
    this.customCss = dto?.setting.customCss || "";
    this.numberItems =
      dto?.setting.numberItems || AppSettingWidget.Tiktok.LimitVideos;
    this.products = dto?.products || [];
    this.disableShowItems = dto?.disableShowItems || [];
    this.itemSorts = dto?.itemSorts || [];
    this.itemsGetmore =
      dto?.itemsGetmore || AppSettingWidget.Tiktok.DefaultItemGetmore;
    this.formatCurrentcy = dto?.shops?.currency || "";
    this.buttonColor = dto?.setting?.buttonColor || "#FF0B53";
    if (dto?.contextConfiguration) {
      this.contextConfiguration = dto?.contextConfiguration;
    } else {
      this.contextConfiguration = {
        mobileHeaderEnable: this.header === "enable",
        mobileShowProfile: this.showProfile === "enable",
        mobileShowNetworkIcon: this.showNetworkIcon === "enable",
        mobileHeaderTitle: this.titleHeader,
        mobileHeaderCaption: this.caption,
        mobileLabelReadMore: this.labelReadMore,
        mobileLabelViewMore: this.labelReadMore,
        mobileAccentColor: this.accentColor,
        mobileBackGround: this.itemBackground,
        mobileColor: this.itemColor,
        mobileNumberPerRow: this.numberItemPerRow,
        mobileNumberItems: this.numberItems,
        mobileButtonColor: this.buttonColor,
        mobileCustomCss: "",
        mobileLayoutType: this.layout
      };
    }
  }
  ToDto = (): ISettingProviderWidget => {
    return this as ISettingProviderWidget;
  };

  AddFormatCurrentcy = (formatCurrentcy?: string) => {
    this.formatCurrentcy = formatCurrentcy;
    return this;
  };
}

export type WidgetStatus = "FirstCreated" | "Undefined";
export class WidgetStoreModelDto {
  step: number;
  products: ProductResponse[] | undefined;
  count: number | undefined;
  status: WidgetStatus;
  sequenceNumber: number;
  workingSearch: boolean;
  needLoginUsers?: number;
  constructor() {
    this.step = 0;
    this.products = [];
    this.count = undefined;
    this.status = "Undefined";
    this.sequenceNumber = 0;
    this.workingSearch = false;
  }
}

export class WidgetStoreModel implements ICloneStore<WidgetStoreModel> {
  protected _step: number;
  protected _products: ProductResponse[];
  protected _count: number | undefined;
  protected _needLoginUsers?: number;
  protected _status: WidgetStatus;
  protected _sequenceNumber: number;
  protected _workingSearch: boolean;
  constructor(_dto?: WidgetStoreModelDto) {
    this._step = _dto?.step || -1;
    this._products = _dto?.products || [];
    this._count = _dto?.count ?? -1;
    this._status = _dto?.status ?? "Undefined";
    this._sequenceNumber = _dto?.sequenceNumber ?? 0;
    this._workingSearch = _dto?.workingSearch ?? false;
    this._needLoginUsers = _dto?.needLoginUsers;
  }
  public get needLoginUsers(): number | undefined {
    return this._needLoginUsers;
  }

  public set needLoginUsers(v: number | undefined) {
    this._needLoginUsers = v;
  }

  public get workingSearch(): boolean {
    return this._workingSearch;
  }

  public set workingSearch(v: boolean) {
    this._workingSearch = v;
  }

  public get sequenceNumber(): number {
    return this._sequenceNumber;
  }

  public set sequenceNumber(v: number) {
    this._sequenceNumber = v;
  }

  public get status(): WidgetStatus {
    return this._status;
  }

  public set status(v: WidgetStatus) {
    this._status = v;
  }

  public get count(): number | undefined {
    return this._count;
  }

  public set count(v: number | undefined) {
    this._count = v;
  }

  public get products(): ProductResponse[] {
    return this._products;
  }

  public set products(v: ProductResponse[]) {
    this._products = v;
  }

  public get step(): number {
    return this._step;
  }

  public set step(v: number) {
    this._step = v;
  }
  Clone(): WidgetStoreModel {
    let dto = this.ToDto();
    return new WidgetStoreModel(dto);
  }

  ToDto(): WidgetStoreModelDto {
    return {
      step: this._step,
      products: this._products,
      count: this._count,
      status: this._status,
      sequenceNumber: this._sequenceNumber,
      workingSearch: this._workingSearch,
      needLoginUsers: this.needLoginUsers ?? 0
    };
  }
}
