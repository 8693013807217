import { ProductResponse } from "repositories/dtos/responses/ProductResponse";
import { WidgetActEnum } from "./enum";
import { WidgetType } from "./model";
import { WidgetStatus, WidgetStoreModel } from "./state";
import { GetWidgetCountResponse } from "repositories/dtos/responses/GetWidgetCountResponse";

const WidgetReducer = (
  state: WidgetStoreModel = new WidgetStoreModel(),
  action: WidgetType
): WidgetStoreModel => {
  switch (action.type) {
    case WidgetActEnum.STEP:
      return OnStep(state, action.payload);
    case WidgetActEnum.GET_TAG_PRODUCTS:
      return OnGetTagProducts(state, action.payload, action.isReplace);
    case WidgetActEnum.COUNT:
      return OnSetCount(state, action.payload);
    case WidgetActEnum.CHANGE_STATUS:
      return OnChangeStatus(state, action.payload);
    case WidgetActEnum.RISE_SEQUENCENUMBER:
      return RiseSequenceNumber(state);
    case WidgetActEnum.SEARCHING:
      return SearchingVideos(state, action.payload);
    default:
      return state;
  }
};

const OnStep = (state: WidgetStoreModel, payload: number = 0) => {
  let copyState = state.Clone();
  copyState.step = payload;
  return copyState;
};

const OnGetTagProducts = (
  state: WidgetStoreModel,
  payload: ProductResponse[],
  isReplace = false
) => {
  let copyState = state.Clone();
  if (!isReplace) copyState.products = [...copyState.products, ...payload];
  else copyState.products = payload;
  return copyState;
};
const OnSetCount = (
  state: WidgetStoreModel,
  payload: GetWidgetCountResponse
) => {
  let copyState = state.Clone();
  copyState.count = payload.count;
  if (payload.needLoginUsers) {
    copyState.needLoginUsers = payload.needLoginUsers;
  }
  return copyState;
};

const OnChangeStatus = (state: WidgetStoreModel, status: WidgetStatus) => {
  let copyState = state.Clone();
  if (copyState.count === 0) {
    copyState.status = status;
  } else {
    copyState.status = "Undefined";
  }
  return copyState;
};

const RiseSequenceNumber = (state: WidgetStoreModel) => {
  let copyState = state.Clone();
  copyState.sequenceNumber += 1;
  return copyState;
};

const SearchingVideos = (state: WidgetStoreModel, payload: boolean) => {
  let copyState = state.Clone();
  copyState.workingSearch = payload;
  return copyState;
};
export default WidgetReducer;
