import { ProductResponse } from "repositories/dtos/responses/ProductResponse";
import { InstagramWidgetActEnum } from "./enum";
import { InstagramStoriesType } from "./model";
import { InstagramWidgetStatus, InstagramWidgetStoreModel } from "./state";
import { GetWidgetCountResponse } from "repositories/dtos/responses/GetWidgetCountResponse";

const OnStep = (state: InstagramWidgetStoreModel, payload: number = 0) => {
  let copyState = state.Clone();
  copyState.step = payload;
  return copyState;
};

const OnGetTagProducts = (
  state: InstagramWidgetStoreModel,
  payload: ProductResponse[],
  isReplace = false
) => {
  let copyState = state.Clone();
  if (!isReplace) copyState.products = [...copyState.products, ...payload];
  else copyState.products = payload;
  return copyState;
};

const OnSetCount = (state: InstagramWidgetStoreModel, payload: number) => {
  let copyState = state.Clone();
  copyState.count = payload;
  return copyState;
};

const OnChangeStatus = (
  state: InstagramWidgetStoreModel,
  status: InstagramWidgetStatus
) => {
  let copyState = state.Clone();
  copyState.status = status;
  return copyState;
};

const RiseSequenceNumber = (state: InstagramWidgetStoreModel) => {
  let copyState = state.Clone();
  copyState.sequenceNumber += 1;
  return copyState;
};

const SearchingVideos = (
  state: InstagramWidgetStoreModel,
  payload: boolean
) => {
  let copyState = state.Clone();
  copyState.workingSearch = payload;
  return copyState;
};

const InstagramStoriesReducer = (
  state: InstagramWidgetStoreModel = new InstagramWidgetStoreModel(),
  action: InstagramStoriesType
): InstagramWidgetStoreModel => {
  switch (action.type) {
    case InstagramWidgetActEnum.STEP:
      return OnStep(state, action.payload);
    case InstagramWidgetActEnum.GET_TAG_PRODUCTS:
      return OnGetTagProducts(state, action.payload, action.isReplace);
    case InstagramWidgetActEnum.COUNT:
      return OnSetCount(state, action.payload);
    case InstagramWidgetActEnum.CHANGE_STATUS:
      return OnChangeStatus(state, action.payload);
    case InstagramWidgetActEnum.RISE_SEQUENCENUMBER:
      return RiseSequenceNumber(state);
    case InstagramWidgetActEnum.SEARCHING:
      return SearchingVideos(state, action.payload);
    default:
      return state;
  }
};

export default InstagramStoriesReducer;
