import { ShopDescriptor } from "repositories/dtos/responses/ShopResponse";
import { ShopActEnum } from "./enum";
import { ShopStoreModel, ShopStoreModelDto, ShopType } from "./state";

const ShopReducer = (
  state: ShopStoreModel = new ShopStoreModel(),
  action: ShopType
): ShopStoreModel => {
  switch (action.type) {
    case ShopActEnum.INFORMATION:
      return OnSetInformation(state, action.payload);
    case ShopActEnum.DESCRIPTOR:
      return OnSetDescriptor(state, action.payload);
    default:
      return state;
  }
};

const OnSetInformation = (
  state: ShopStoreModel,
  payload: ShopStoreModelDto
) => {
  let copyState = state;
  if (payload.shop) copyState.shop = payload.shop;
  return copyState;
};

const OnSetDescriptor = (state: ShopStoreModel, payload: ShopDescriptor) => {
  let copyState = state;
  if (payload) copyState.shop.shopDescriptor = payload;
  return copyState;
};

export default ShopReducer;
