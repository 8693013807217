import {
  PricingScope,
  ShopResponse
} from "repositories/dtos/responses/ShopResponse";
import { ShopActEnum } from "./enum";

export type ShopType = {
  type: ShopActEnum;
  payload: any;
};
export type ShopStoreModelDto = {
  shop: ShopResponse;
};
export class ShopStoreModel {
  public shop: ShopResponse;
  constructor(_dto?: ShopStoreModel) {
    this.shop = _dto?.shop ?? {
      id: 0,
      domain: "",
      shopPricing: {
        pricing: PricingScope.Basic,
        isBillingMonthy: false
      }
    };
  }
}
